import React from 'react'
// import styled from 'styled-components'
import styled, { keyframes } from 'styled-components'

// const atomSpinnerAnimation1 = keyframes`
//   100% {
//     transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
//   }
// `

// const atomSpinnerAnimation2 = keyframes`
//   100% {
//     transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
//   }
// `

// const atomSpinnerAnimation3 = keyframes`
//   100% {
//     transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
//   }
// `

const Wrapper = styled.div`
  position: fixed;
  height: 75px;
  width: 75px;
  overflow: hidden;
  left: 60%;
  top: 50%;
  z-index: 1000;
`

const WrapperInner = styled.div`
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
`

// const SpinnerLine = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   border-radius: 50%;
//   animation-duration: 1s;
//   border-left-width: calc(60px / 25);
//   border-top-width: calc(60px / 25);
//   border-left-color: #2eab8e;
//   border-left-style: solid;
//   border-top-style: solid;
//   border-top-color: transparent;

//   :nth-child(1) {
//     animation: ${atomSpinnerAnimation1} 1s linear infinite;
//     transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
//   }

//   :nth-child(2) {
//     animation: ${atomSpinnerAnimation2} 1s linear infinite;
//     transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
//   }

//   :nth-child(3) {
//     animation: ${atomSpinnerAnimation3} 1s linear infinite;
//     transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
//   }
// `

// const SpinnerCircle = styled.div`
//   display: block;
//   position: absolute;
//   color: #2eab8e;
//   font-size: calc(60px * 0.24);
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//`

const ldsringAmin = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
 `

const LdsRing = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #8065e0;
    border-radius: 50%;
    animation: ${ldsringAmin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #8065e0 transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`

const Spinner = () => {
  return (
    <Wrapper>
      <WrapperInner>
        <LdsRing>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </LdsRing>
      </WrapperInner>
    </Wrapper>
  )
}

export default Spinner
