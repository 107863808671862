import React, { useEffect, useRef, useState } from 'react'
import s from "./text-spinner.styles"

const TextListLinks = [
    'Уже генерируем ссылку',
    'Еще минуточку',
    'Уже загружается',
    'Еще немного',
    'Еще чуть-чуть',
    'Почти готово',
]
const TextListPayment = [
    'Подтверждаем ордер',
    'Уже проверяем информацию',
    'Еще немного',
    'Скоро переведем вас на главную страницу',
]
const TextListConfirm = [
    'Подтверждаем ордер',
    'Уже проверяем информацию',
    'Еще немного',
    'Скоро переведем вас на главную страницу',
]

const TextSpinner = ({ isLinksLoading, paymentDetails }) => {


    const [isSpinner, setIsSpinner] = useState(0)
    const [textIndex, setTextIndex] = useState(0)
    const [textList, setTextList] = useState(TextListLinks)

    const timerIdRef = useRef(null)

    // useEffect(() => {
    //     timerIdRef.current = setTimeout(() => {
    //         if (textList.length > textIndex) {
    //             setTextIndex(value => value + 1)
    //         } else {
    //             setTextIndex(0)
    //         }
    //         console.log('setTimeout')
    //     }, 3000)
    //     return () => clearTimeout(timerIdRef.current);
    // }, [textIndex])

    const startTimer = () => {
        timerIdRef.current = setTimeout(() => {
            if (textList.length > textIndex) {
                setTextIndex(value => value + 1)
            } else {
                setTextIndex(0)
            }
            startTimer()
            console.log('setTimeout')
        }, 1000)
    }

    useEffect(() => {
        if (isLinksLoading) {
            clearTimeout(timerIdRef.current);
            startTimer()
            setIsSpinner(true)
            setTextList(TextListLinks)
        } else {
            setTextIndex(0)
            setIsSpinner(false)
        }
    }, [isLinksLoading])

    useEffect(() => {
        const isPending = paymentDetails?.paymentFlow?.every(el => el?.payed) && paymentDetails?.statusName !== "Оплачено"
        if (isPending) {
            clearTimeout(timerIdRef.current);
            startTimer()
            setIsSpinner(true)
            setTextList(TextListPayment)
        } else {
            setTextIndex(0)
            setIsSpinner(false)
        }
    }, [paymentDetails])

    return (
        (isSpinner
            &&
            <s.Wrapper>
                <s.Spinner />
                <s.Text>{textList[textIndex]}...</s.Text>
            </s.Wrapper>)
        || null
    )
}
export default TextSpinner