import styled, { keyframes } from 'styled-components'

const rolling = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`
const Spinner = styled.div`
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: 3px;
    :after {
        content: " ";
        display: block;
        width: 12px;
        height: 12px;
        margin: 0px;
        border-radius: 50%;
        border: 2px solid #ddd;
        border-color: #ddd transparent #ddd transparent;
        animation: 1s ${rolling} linear infinite;
    }
`
const Wrapper = styled.div`
    padding: 15px;
    text-align: center;
` 

const Text = styled.span`
    padding-left: 10px;
    color: #666;
`

const s = {
    Spinner,
    Wrapper,
    Text
}
export default s