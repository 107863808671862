import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import GlobalStyle from '../../style/global-style'
import { themeSettings } from '../../style/theme'
import PaymentForm from '../payment-form/payment-form'
import { StyledFooter } from '../payment-form/payment-form-styles'

const Container = styled.div`
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 64px;
  min-height: 480px;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.85);
  border-radius: 20px;

  @media (min-width: 768px) {
    width: 720px;
  }
`

export default function App({ title }) {
  return (
    <ThemeProvider theme={themeSettings}>
      <GlobalStyle />
      <Container>
        <PaymentForm />
      </Container>
      <StyledFooter>
        <p>Ваши данные под защитой</p>
        <span>
          Реквизиты банковской карты и регистрационные данные передаются по
          защищенным протоколам и не попадут в интернет-магазин и третьим лицам.
          Платежи обрабатываются на защищенной странице процессинга по стандарту
          PCI DSS – Payment Card Industry Data Security Standard.
        </span>
      </StyledFooter>
    </ThemeProvider>
  )
}
