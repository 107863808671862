import { createGlobalStyle } from 'styled-components'
import InterRegular from '../assets/fonts/Inter-Regular.woff2'
import InterBold from '../assets/fonts/Inter-Bold.woff2'
import InterMedium from '../assets/fonts/Inter-Medium.woff2'

const GlobalStyle = createGlobalStyle`
 *, ::before, ::after {
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: #f7f7f7;
}

fieldset {
  all: unset;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('${InterRegular}') format('woff2')
}

@font-face {
  font-family: 'Inter';
  font-style: bold;
  font-weight: 700;
  src: url('${InterBold}') format('woff2')
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('${InterMedium}') format('woff2')
}
`

export default GlobalStyle
