import React from 'react'
import Countdown from 'react-countdown'
import {
  CountDownBlock,
  LinksBlock,
  ListTitle,
  PaymentLink,
  PaymentList
} from '../payment-details/payment-details-styles'
import Spinner from '../spinner/spinner'

export default function PaymentLinks({ paymentDetails, isLoading }) {
  const getLinkTitle = (idx, paymentURL) => {
    if (!paymentURL) return `loading...`

    if (
      paymentDetails &&
      paymentDetails.displaySettings &&
      paymentDetails.displaySettings.linkTitle
    ) {
      return `${paymentDetails.displaySettings.linkTitle}`
    } else {
      return `ссылка на оплату №${idx + 1}`
    }
  }

  return (
    <>
      <CountDownBlock>
        {paymentDetails.paymentFlow &&
        paymentDetails.paymentFlow.every(({ payed }) => payed === true) ? (
          <span
            style={{
              fontWeight: `700`,
              color: `#000`,
              fontSize: `26px`
            }}
          >
            Оплачено
          </span>
        ) : (
          <>
            Счет действителен {` `}
            <Countdown
              date={Date.now() + (paymentDetails.expireAt * 1000 - Date.now())}
            />
          </>
        )}
      </CountDownBlock>

      <LinksBlock>
        {isLoading && <Spinner />}
        {paymentDetails.paymentFlow && (
          <>
            <ListTitle payed={false}>Не оплаченные</ListTitle>
            {paymentDetails.paymentFlow.map(({ paymentURL, payed }, idx) => {
              if (!payed) {
                return (
                  <PaymentList key={paymentURL}>
                    <PaymentLink
                      payed={payed}
                      href={paymentURL}
                      target='_blank'
                      rel='noreferer'
                    >
                      {getLinkTitle(idx, paymentURL)}
                    </PaymentLink>
                  </PaymentList>
                )
              }
            })}
            <ListTitle payed={true}>Оплаченные</ListTitle>
            {paymentDetails.paymentFlow.map(({ paymentURL, payed }, idx) => {
              if (payed) {
                return (
                  <PaymentList key={paymentURL}>
                    <PaymentLink
                      payed={payed}
                      href={paymentURL}
                      target='_blank'
                      rel='noreferer'
                    >
                      {paymentURL
                        ? `ссылка на оплату №${idx + 1}`
                        : `loading...`}
                    </PaymentLink>
                  </PaymentList>
                )
              }
            })}
          </>
        )}
      </LinksBlock>
    </>
  )
}
