import React from 'react'
import {
  Icon,
  LabelContainer,
  OptionsContainer,
  Radio
} from '../payment-form/payment-form-styles'
import * as Icons from '../../icons/icons'

export default function Option({
  name,
  setPaySource,
  label
}) {
  const iconName = name.toUpperCase()
  return (
    <OptionsContainer>
      <Icon>{Icons[iconName]()}</Icon>
      <LabelContainer>
        <strong>{label}</strong>
      </LabelContainer>
      <Radio
        name='processing'
        type='radio'
        onChange={() => setPaySource(name)}
      />
    </OptionsContainer>
  )
}
