export const Endpoint = {
  GENERATE_INVOICE_P2P_BY_REQUEST: `/payment/generate_invoice_p2p_by_request`,
  GET_P2P_REQUEST_FORM_DETAILS: `/payment/get_p2p_request_form_details`,

  GENERATE_INVOICE_FORM: `/payment/generate_invoice_form`,
  GET_INVOICE_FORM_DETAILS: `/payment/get_invoice_form_details`,
  GENERATE_INVOICE_BY_FORM: `/payment/generate_invoice_by_form`
}

export const PaymentStatus = {
  START: `Заявка создана`,
  INITIAL: `Ожидает оплаты`,
  PENDING: `Обработка платежа`,
  PAYED: `Оплачено`,
  EXPIRED: `Время на оплату вышло (не оплачено)`,
  DECLINED: `Отклонено банком`
}
