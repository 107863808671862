import React, { useEffect } from 'react'
import { Container, PaymentBlock } from './payment-details-styles'

export default function PaymentDetails({ paymentDetails }) {
  const { amount, paymentFlow, redirectURL, statusName } = paymentDetails

  useEffect(() => {
    if (paymentFlow) {
      if (statusName === `Оплачено`) {
        setTimeout(() => {
          if (redirectURL) {
            window.location.replace(redirectURL)
          }
        }, 5000)
      }
    }
  }, [paymentFlow])

  return (
    <Container>
      <PaymentBlock>
        <span>Сумма платежа</span>
        <strong>{amount} ₽</strong>
      </PaymentBlock>
    </Container>
  )
}
